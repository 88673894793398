@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400&display=swap");
@import "react-popper-tooltip/dist/styles.css";
@import "react-h5-audio-player/lib/styles.css";
@import "react-loading-skeleton/dist/skeleton.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .rhap_time {
    @apply dark:text-white;
  }
}
