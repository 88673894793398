.rhap_progress-section {
  width: 100%;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
}
.rhap_progress-bar {
  background-color: rgb(0, 0, 0, 0.2);
}
.rhap_progress-indicator {
  background-color: black;
  width: "10px";
  height: "10px";
}
.rhap_progress-filled {
  background-color: black;
}
.rhap_download-progress {
  background-color: rgb(192, 192, 192);
}

/* .rhap_time {
 color:rgb(228, 225, 225);
  
} */